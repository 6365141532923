/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* Google Fonts */

:root{
  --header-height: 3rem;
  /*================================ Colors ================================*/
  --hue: 0;
  --sat: 0%;
  --title-color: hsl(var(--hue), var(--sat), 20%);
  --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 46%);
  --body-color: hsl(var(--hue), var(--sat), 98%);
  --container-color: #fff;

  /*================================ Fonts and typography ================================*/
  --body-font: "Poppins" , sans-serif;

  /* .5rem = 8px | 1rem = 16px ... */
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /* ========= Font Weight =========*/
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /* ========= Mergenes Bottom =========*/
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;
  /* ========= z Index =========*/
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}
/* Responsive Typography */
@media screen and  (max-width: 992px){
  :root {
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.928rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}
/* ========= BASE =========*/

*{
  margin: 0;
  padding: 0;
  box-sizing:  border-box;
}
html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
   background-color: var(--body-color);
   color: var(--text-color);
}
h1,h2,h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}
ul{
  list-style: none;
}
ul{
  list-style: none;
}
a{
  text-decoration: none;
}
button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* ========= Reusable CSS Classes =========*/
.section {
  padding: 6rem 0 2rem;
}

.section__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 4rem;
}

.section__title,
.section__subtitle {
  text-align: center;
}
/* ========= Layout =========*/
.container {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}
.grid{
  display: grid;
  gap: 1.5rem;
}

/* ========= Buttons =========*/
.button {
  display: inline-block;
  background-color: var(--title-color);
  color: var(--container-color);
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: var(--font-medium);
}

.button:hover {
  background-color: var(--title-color-dark);
}
.button__icon {
  margin-left: var(--mb-0-5);
}
.button--flex {
  display: inline-flex;
  align-items: center;
}

@media  screen and (max-width: 992px) {
  .container {
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);

  }

  .button {
    padding : 1rem 1.75rem;
  }
  .button__icon {
    width: 22px;
    height: 22%;
  }
}
@media  screen and (max-width: 768px) {
  .section {
    padding: 2rem 0 4rem;
    /* margin-right: var(--mb-1); */

  }
}
@media  screen and (max-width: 350px) {
  :root {
    --big-font-size: 2.25rem;
  }
  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);

  }
}

body {
  justify-content: center;
  box-sizing: border-box;
}

.absolute {
  position: absolute;
  top: 50px !important;
  inset: 0;
  justify-content: center;
  display: inline-flex;
  flex-direction: row;
}

.justify-center {
  justify-content: center;
}
.bg-shape1 {
  width: 100%;
  height: 100%;
  border-radius: 2000px;
  position: relative;
  z-index:-10000;
  animation: one 10s infinite;
}

.bg-shape2 {
  width: 100%;
  height: 100%;
  border-radius: 2000px;
  position: relative;
  z-index:-10000;
  animation: two 10s infinite;
}
@keyframes one {
  0% { left: 0px; top: 0px; }
  25% { left: -100px; top: 70px; }
  50% { left: 50px; top: 150px; }
  75% { left: 40px; top: 100px; }
  100% { left: 0px; top: 0px; }
}

.opacity-50 {
  opacity: .5;
}

.bg-blur {
  filter: blur(70px);
}
.bg-primary {
  background-color: rgb(30,0,255);
}

.bg-teal {
  background-color: rgb(255,72,173);
}

.bg-purple {
  background-color: rgb(140,72,215);
}