.qualification__container {
    max-width: 768px;
    margin: auto;
  }
  
  .qualification__tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  
  .qualification__button {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--title-color);
    margin: 0 1rem;
    cursor: pointer;
    z-index: 10000;
  }
  
  .qualification__active {
    color: var(--title-color-dark);
  }
  
  .qualification__sections {
    position: relative;
  }
  
  .qualification__content {
    display: none;
  }
  
  .qualification__content-active {
    display: block;
  }
  
  .qualification__data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    align-items: center;
    column-gap: 1.25rem;
    margin-bottom: 2rem;
  }
  
  .qualification__title {
    font-size: 1rem;
    font-weight: 600;
  }
  
  .qualification__subtitle {
    font-size: 0.875rem;
    color: var(--text-color);
  }
  
  .qualification__calender {
    font-size: 0.875rem;
    color: var(--text-color);
  }
  
  .qualification__rounder {
    width: 13px;
    height: 13px;
    background-color: black;
    border-radius: 50%;
    z-index: 10;
    color: black;
  }
  
  .qualification__line {
    width: 2px;
    height: 100%;
    background-color: var(--text-color);
    transform: translateY(-7px);
    /* z-index: ; */
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .qualification__data {
      grid-template-columns: 1fr;
      text-align: center;
    }
  
    /* .qualification__line {
      display: none;
    } */
  }
  